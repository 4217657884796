<template>
  <div class="success-page">
    <!-- Success Modal -->
    <b-modal
      size="md"
      ref="success_modal"
      @hidden="closeSuccessModal"
      dialog-class="success_modal"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center p-5">
        <img
          class="success-img"
          height="60" width="60"
          src="../../assets/img/main/check.svg"
          alt="Success Svg"
        />
        <h3 class="mt-3">
          Done 
        </h3>
        <h5 class="mt-3">
          Our representative will contact you within two days to set a time.
        </h5>
        <h5 class="mt-3">
          Thank you very much for choosing Green Closet.
        </h5>
        <h5>
          We hope you have a nice day.
        </h5>
      </div>
    </b-modal>
    <!-- Success Modal End-->
  </div>
</template>

<script>
export default {
  created() {
    window.fbq("track", "Order-Success");
  },
  mounted() {
    this.$refs["success_modal"].show();
  },
  methods: {
    closeSuccessModal() {
      this.$refs["success_modal"].hide();
      this.$router.push("/en");
    },
  },
};
</script>

<style>
</style>